import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  companyName: string = environment.companyName;
  companyURL: string = environment.companyURL;
  logo: string = environment.ACS_LOGO_IMAGE;
  whyCustomBooksLink: string = environment.whyCustomBooksLink;
  businessLink: string = environment.businessLink;
  overviewLink: string = environment.overviewLink;
  findAnAdvisorLink: string = environment.findAnAdvisorLink;
  accountingReportingLink: string = environment.accountingReportingLink;
  salesLink: string = environment.salesLink;
  purchasingLink: string = environment.purchasingLink;
  projectAndTimeTrackingLink: string = environment.projectAndTimeTrackingLink;
  inventoryManagementLink: string = environment.inventoryManagementLink;
  partnersLink: string = environment.partnersLink;
  accountantAndBookkeeperLink: string = environment.accountantAndBookkeeperLink;
  webinarsLink: string = environment.webinarsLink;
  integrationsLink: string = environment.integrationsLink;
  getStartedLink: string = environment.getStartedLink;
  userGuideLink: string = environment.userGuideLink;
  accountingWebinarsLink: string = environment.accountingWebinarsLink;
  bookkeepingWebinarsLink: string = environment.bookkeepingWebinarsLink;
  productEnhancementsLink: string = environment.productEnhancementsLink;
  accountingSuiteAcademyLink: string = environment.accountingSuiteAcademyLink;
  accountingSuiteStatusLink: string = environment.accountingSuiteStatusLink;
  aboutContactLink: string = environment.aboutContactLink;
  pricingLink: string = environment.pricingLink;
  developerLink: string = environment.DEVELOPER_URL;
  blogURL: string = environment.blogURL;
  supportURL: string = environment.supportURL;
  supportPortalURL: string = environment.supportPortalURL;
  loginURL: string = environment.LOGIN_URL;

  faBars = faBars;
  constructor() { }

}
