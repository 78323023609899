import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {
  api: string = environment.ROCKSTAR_URL;
  constructor(
    private httpClient: HttpClient
  ) { }

  getTokenDetails(token: string): Promise<any> {
    return this.httpClient.post(this.api + '/get_review_invite_details', { token }).toPromise();
  }

  saveReview(
    token: string,
    email: string,
    name: string,
    rating: number,
    message: string
  ): any {
    return this.httpClient.post(this.api + '/save_review', {
      token,
      email,
      name,
      rating,
      message
    }).toPromise();
  }
}
