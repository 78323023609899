<div class="container home-body">
    <div class="row justify-content-md-center">
      <div class="col col-lg-6 div-border">
        <div class='text-center'>
            <img class="brand_logo" src='assets/images/acs_logo_110_110.jpg'>
        </div>
        <div class="justify-content-center">
            <div class="input-group mb-3 mt-3 justify-content-md-center">
                <h4>404 - PAGE NOT FOUND</h4>
            </div>
            <div class="input-group mb-3">
                <p>This page you are looking for might have been removed or had its name changed or is temporarily unavailable.</p>
            </div>
            <div class="input-group mb-3 justify-content-md-center">
                <a type="button" name="button" class="btn login_btn" href="{{ loginURL }}">Go to Home</a>
            </div>
        </div>
      </div>
    </div>
</div>