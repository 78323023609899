<div class="map" *ngIf="isGoogleApiReady$ | async">
  <google-map
    [options]="googleMapOptions"
    width="100%" height="100%"
    (mapInitialized)="mapReady($event)">
    <button id="myLocation" class="btn btn-location m-2" (click)="setCurrentLocation()">
      <fa-icon [icon]="faLocationArrow" title="Location" size="1x"></fa-icon>
    </button>
    <map-marker-clusterer [imagePath]="'assets/images/map_marker_cluster'">
      <map-marker *ngFor="let marker of markers"
                  #mapMarker="mapMarker"
                  [position]="marker.position"
                  [label]="marker.label"
                  [draggable]="marker.draggable"
                  [icon]="{ url: 'assets/images/map_marker_accountant.png' }"
                  (mapClick)="openInfoWindow(mapMarker, infoWindow)"
                  [options]="marker.options">
        <map-info-window #infoWindow="mapInfoWindow">
          <div class="infobox-big-accountant">
            <div class="title">
              <a [routerLink]="['/profile', marker.rockStarProfileUrlName]"> {{ marker.fullName }}</a>
            </div>
            <div class="content">
              <ng-container *ngIf="marker.profile_image; else appImage">
                <div class="app-avatar-wrapper">
                  <div class="app-avatar">
                    <img class="app-avatar-img" src="{{ marker?.profile_image }}" alt="">
                  </div>
                </div>
              </ng-container>
              <ng-template #appImage>
                <div class="app-avatar-wrapper">
                  <div class="app-avatar">
                    <img class="app-avatar-img app-avatar-img-default" src="assets/images/default_profile.png" alt="">
                  </div>
                </div>
                <!-- <app-image [(userId)]="m.id" [type]="'fan-club-profile'"></app-image> -->
              </ng-template>
              <div class="subtitle" (click)="appLog(marker)">Contacts</div>
              <div [hidden]="!marker.address">
                <p>{{ marker.address }}</p>
              </div>
              <div [hidden]="marker.hideUserEmail">
                <div [hidden]="!marker.email">
                  <strong>e-mail:</strong> <a href="mailto:{{ marker.email }}" class="primary-link">&nbsp;{{ marker.email }}</a>
                </div>
              </div>
              <div [hidden]="marker.hideUserPhoneNumber">
                <div [hidden]="!marker.phoneNumber">
                  <strong>Phone:</strong> <a href="tel:{{ marker.phoneNumber }}" class="primary-link">&nbsp;{{ marker.phoneNumber }}</a>
                </div>
              </div>
              <div [hidden]="!marker.website">
                <br><strong>
                www:</strong>&nbsp;<a target="_blank" rel="noopener" href="{{ marker.website }}" class="primary-link">{{ marker.website | lowercase }}</a>
              </div>
            </div>
          </div>
        </map-info-window>
      </map-marker>
    </map-marker-clusterer>
  </google-map>
</div>
