import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { AuthService } from '@app/services/auth.service';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  api = environment.ROCKSTAR_URL;
  constructor(
    private toastr: ToastrService,
    public authService: AuthService
    ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler):
   Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | HttpEvent<any> | any> {
    if (request.url !== environment.ROCKSTAR_URL + '/token') {
      request = request.clone({
        setHeaders: {
          Authorization: `Basic ${this.authService.getToken()}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error.status === 401) {
          this.toastr.error('The page has expired due to inactivity. Please refresh and try again.', 'Session Timeout!');
        }
        return throwError(error);
      })
    );
  }
}
