<header>
    <!-- Fixed navbar -->
    <nav class="navbar navbar-expand-md header-acs">
      <div class="container-fluid">
        <a class="navbar-brand" href="{{ companyURL }}">
          <img class="logo" alt="{{ companyName }}" src="./../../../assets/images/{{ logo }}" title="{{ companyName }}">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <fa-icon [icon]="faBars" [styles]="{'color': '#cccccc'}"></fa-icon>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <a class="nav-link" href="{{ whyCustomBooksLink }}">Why {{ companyName }}</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="{{ businessLink }}" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">For Businesses</a>
              <div class="dropdown-menu" aria-labelledby="dropdown01">
                  <li class="dropdown-item"><a href="{{ overviewLink }}">Overview</a></li>
                  <li class="dropdown-item"><a href="{{ findAnAdvisorLink }}">Find an Advisor</a></li>
                  <li class="dropdown-item"><a href="{{ accountingReportingLink }}">Accounting &amp; Reporting</a></li>
                  <li class="dropdown-item"><a href="{{ salesLink }}">Sales</a></li>
                  <li class="dropdown-item"><a href="{{ purchasingLink }}">Purchasing</a></li>
                  <li class="dropdown-item"><a href="{{ projectAndTimeTrackingLink }}/">Project and Time Tracking</a></li>
                  <li class="dropdown-item"><a href="{{ inventoryManagementLink }}">Inventory Management</a></li>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="{{ partnersLink }}" id="dropdown02" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">For Partners</a>
              <div class="dropdown-menu" aria-labelledby="dropdown02">
                  <li class="dropdown-item"><a href="{{ accountantAndBookkeeperLink }}">Accountants &amp; Bookkeepers</a></li>
                  <li class="dropdown-item"><a href="{{ webinarsLink }}">Webinars</a></li>
                  <li class="dropdown-item"><a href="{{ integrationsLink }}">Integrations</a></li>
                  <li class="dropdown-item"><a target="_blank" href="{{ developerLink }}">Developer</a></li>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="{{ supportURL }}" id="dropdown03" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Support</a>
              <div class="dropdown-menu" aria-labelledby="dropdown03">
                  <li class="dropdown-item"><a href="{{ getStartedLink }}">Get Started</a></li>
                  <li class="dropdown-item"><a href="{{ accountingWebinarsLink }}">Accounting Webinars</a></li>
                  <li class="dropdown-item"><a href="{{ bookkeepingWebinarsLink }}">Bookkeeping Webinars</a></li>
                  <li class="dropdown-item"><a target="_blank" href="{{ userGuideLink }}/">User Guide</a></li>
                  <li class="dropdown-item"><a href="{{ productEnhancementsLink }}">Product Enhancements</a></li>
                  <li class="dropdown-item"><a target="_blank" href="{{ accountingSuiteAcademyLink }}">{{ companyName }} Academy</a></li>
                  <li class="dropdown-item"><a href="{{ accountingSuiteStatusLink }}">{{ companyName }} Status</a></li>
                  <li class="dropdown-item"><a href="{{ supportPortalURL }}">Support Portal</a></li>
                  <li class="dropdown-item"><a href="{{ aboutContactLink }}">About &amp; Contact</a></li>
                  <li class="dropdown-item"><a href="{{ blogURL }}">Blog</a></li>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="{{ pricingLink }}">Pricing</a>
            </li>
          </ul>
          <form class="form-inline mt-2 mt-md-0">
            <a class="btn default-disable-btn mr-2" href="{{ loginURL }}">Login</a>
            <a class="btn default-btn" href="/">RockStar</a>
          </form>
        </div>
      </div>
    </nav>
  </header>