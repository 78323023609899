import { Component, OnInit, Input, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Marker } from '@app/interfaces/marker';
import { MapService } from '@app/services/map.service';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
  selector: 'app-address-map',
  templateUrl: './address-map.component.html',
  styleUrls: ['./address-map.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddressMapComponent implements OnInit, OnDestroy {
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() address: string;
  @Input() email: string;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  marker: Marker;
  currentIW = null;
  previousIW = null;
  googleMapOptions: google.maps.MapOptions;
  isGoogleApiReady$: Observable<boolean>;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(public mapService: MapService,
              private logger: NGXLogger) {
  }

  ngOnInit(): void {
    this.googleMapOptions = {
      zoomControl: false,
      center: {
        lat: this.latitude,
        lng: this.longitude,
      },
      mapTypeId: 'roadmap',
      mapTypeControl: false,
      disableDefaultUI: true,
      fullscreenControl: false
    };
    this.isGoogleApiReady$ = this.mapService.getGoogleMapsApi()
      .pipe(
        map(() => true),
        catchError((error) => {
          this.logger.error('ERROR: -> AddressMapComponent -> OnInit -> getGoogleMapsApi -> error', error);
          return of(false);
        }),
        takeUntil(this.componentDestroyed$)
      );
    this.marker = {
      position: {
        lat: this.latitude,
        lng: this.longitude,
      },
      address: this.address,
      email: this.email,
      draggable: false
    };
  }

  openInfoWindow(marker: MapMarker): void {
    this.infoWindow.open(marker);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }
}
