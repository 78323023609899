<div class="home-body">
    <div class="col profile-container mb-4">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-right">
                    <button [routerLink]="['/']" [queryParams]="{search: lastSearched}" class="btn default-btn mt-3">Back to search</button>
                </div>
                <div class="col-md-12">
                    <div class="profile-box mb-0">
                        <div class="row">
                            <div class="col-md-2">
                                <ng-container *ngIf="accountantDetails?.profile_image; else appImage">
                                    <div class="app-avatar-wrapper">
                                        <div class="app-avatar">
                                            <img class="app-avatar-img" src="{{ accountantDetails?.profile_image }}" alt="">
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #appImage>
                                    <div class="app-avatar-wrapper">
                                        <div class="app-avatar">
                                            <img class="app-avatar-img" src="assets/images/default_profile.png" alt="">
                                        </div>
                                    </div>
                                    <!-- <app-image [(userId)]="accountantDetails.id" [type]="'profile'"></app-image> -->
                                </ng-template>
                            </div>
                            <div class="col-md-4">
                                <h5 class="card-title text-capitalize">{{ accountantDetails.fullName }}
                                    <span class="card-subtitle text-capitalize">&nbsp; {{ accountantDetails.jobTitle }}</span>
                                </h5>
                                <div class="rating" *ngIf="accountantDetails.noOfReviews != 0">
                                    {{ accountantDetails.avgOfReviews }} &nbsp;<bar-rating [readOnly]="true" [rate]="accountantDetails.avgOfReviews" [max]="5"></bar-rating> {{accountantDetails.noOfReviews}} review(s)
                                </div>
                                <p class="card-text text-capitalize company">{{ accountantDetails.company }}</p>
                            </div>
                            <div class="col-md-1">
                                <ng-container *ngIf="accountantDetails?.company_logo; else appCompanyImage">
                                    <div class="app-company-logo-wrapper">
                                        <div class="app-company-logo">
                                            <img class="app-company-logo-img" src="{{ accountantDetails?.company_logo }}" alt="">
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #appCompanyImage>
                                    <div class="app-company-logo-wrapper">
                                        <div class="app-company-logo">
                                            <img class="app-company-logo-img" src="assets/images/company_logo.png" alt="">
                                        </div>
                                    </div>
                                    <!-- <app-image [(userId)]="accountantDetails.id" [type]="'company'"></app-image> -->
                                </ng-template>
                            </div>
                            <div class="col-md-5">
                                <div class="actions" [hidden]="!accountantDetails.coreCertificate && !accountantDetails.advanceInventoryCertificate && !accountantDetails.eCommerceCertificate">
                                    <h4 class="text-center mb-4"><strong> {{ companyName }} Certificates</strong></h4>
                                        <div class="grid-tiles">
                                            <div class="grid-tile" [hidden]="!accountantDetails.coreCertificate">
                                                <a href="#">
                                                    <div class="preview" >
                                                        <img src="assets/images/core-certificate.png" alt="Rockstar Core Certificate">
                                                    </div>
                                                    <div class="caption">
                                                        <h2 class="title">Core</h2>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="grid-tile clearfix" [hidden]="!accountantDetails.eCommerceCertificate">
                                                <a href="">
                                                    <div class="preview">
                                                    <img src="assets/images/e-commerce-certificate.png" alt="Rockstar eCommerce Certificate">
                                                    </div>
                                                    <div class="caption">
                                                        <h2 class="title">eCommerce</h2>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="grid-tile" [hidden]="!accountantDetails.advanceInventoryCertificate">
                                                <a href="">
                                                    <div class="preview">
                                                        <img src="assets/images/advance-inventory-certificate.png" alt="Rockstar Advanced Certificate">
                                                    </div>
                                                    <div class="caption">
                                                        <h2 class="title">Advanced</h2>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <div class="col box" *ngIf="accountantDetails.accountantDetails">
                        <h4 class="title-ul">About me</h4>
                        <p class="details">{{ accountantDetails.accountantDetails }}</p>
                    </div>
                    <div class="col box" *ngIf="accountantDetails.companyDetails">
                        <h4 class="title-ul">About my company</h4>
                        <p class="details">{{ accountantDetails.companyDetails }}</p>
                    </div>
                    <div class="col box" *ngIf="hasColumnsDetails">
                        <div class="row">
                            <div class="col-md-3" *ngIf="accountantDetails.industriesServed">
                                <h4 class="title-ul">Industries Served</h4>
                                <ul class="industriesServed">
                                    <li *ngFor="let industry of accountantDetails.industriesServed | keyvalue" class="ca" [hidden]="!industry.value">
                                        {{ industry.key | replace : '_' : ' ' | titlecase }}
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-3" *ngIf="accountantDetails.servicesProvided">
                                <h4 class="title-ul">Services</h4>
                                <ul class="servicesProvided">
                                    <li *ngFor="let service of accountantDetails.servicesProvided | keyvalue" class="ca" [hidden]="!service.value">
                                        {{ service.key | replace : '_' : ' ' | titlecase }}
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-3" *ngIf="accountantDetails.professionalDesignations">
                                <h4 class="title-ul">Professional Designations</h4>
                                <ul class="professionalDesignations">
                                    <li *ngFor="let professional of accountantDetails.professionalDesignations | keyvalue" class="ca" [hidden]="!professional.value">
                                        {{ professional.key | replace : '_' : ' ' | titlecase }}
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-3" *ngIf="accountantDetails.knownLanguages">
                                <h4 class="title-ul">Languages</h4>
                                <ul class="languages">
                                    <li *ngFor="let languages of accountantDetails.knownLanguages | keyvalue" class="ca" [hidden]="!languages.value">
                                        {{ languages.key | replace : '_' : ' ' | titlecase }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col box" *ngIf="accountantDetails.noOfReviews != 0">
                        <h4 class="title-ul">{{ accountantDetails.noOfReviews }} Review(s)</h4>
                        <div class="row reviews-box" *ngFor="let review of accountantDetails.reviewsList">
                            <div class="col-md-2">
                                <div class="app-avatar-wrapper">
                                    <div class="app-avatar">
                                        <img class="app-avatar-img app-avatar-img-default" src="assets/images/default_profile.png" alt="">
                                    </div>
                                </div>
                                <!-- <app-image [(userId)]="accountantDetails.id" [type]="'user'"></app-image> -->
                            </div>
                            <div class="col-md-10">
                                <h5 class="user-title text-capitalize">{{review.reviewer_name}}</h5>
                                <div class="rating">
                                    {{ review.rating }} &nbsp;<bar-rating [readOnly]="true" [(rate)]="review.rating" [max]="5"></bar-rating>
                                </div>
                                <p>{{ review.reviewer_message }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 pl-md-0">
                    <div class="accountant-details box">
                        <div>
                            <app-address-map
                             [latitude]="accountantDetails.latitude"
                             [longitude]="accountantDetails.longitude"
                             [address]="accountantDetails.address"
                             [email]="accountantDetails.email"></app-address-map>
                        </div>
                        <ul class="list-contact">
                            <li class="icon address" *ngIf="accountantDetails.address">
                                <fa-icon [icon]="faMapMarkerAlt" title="Address" size="1x" class="header-icons" [style]="{color: '#fed221'}"></fa-icon>
                                &nbsp;&nbsp;{{ accountantDetails.address }}
                            </li>
                            <li class="icon phone" *ngIf="!accountantDetails.hideUserPhoneNumber && accountantDetails.phoneNumber">
                                <fa-icon [icon]="faPhone" title="Phone" size="1x" class="header-icons"></fa-icon>
                                &nbsp;<a href="tel:{{ accountantDetails.phoneNumber }}"> {{ accountantDetails.phoneNumber }} </a>
                            </li>
                            <li class="icon email" *ngIf="!accountantDetails.hideUserEmail && accountantDetails.email">
                                <fa-icon [icon]="faEnvelope" title="Email" size="1x" class="header-icons"></fa-icon>
                                &nbsp;<a href="javascript:void(0)" (click)="onCreateMsgForm()"> Contact Rockstar</a>
                                <div *ngIf="messageForm">
                                    <form [formGroup]="sendMessageForm" (ngSubmit)="onSendMessage(sendMessageForm.value)">
                                        <div class="form-group">
                                            <label for="message">Message <span class="text-danger">*</span></label>
                                            <textarea class="form-control" formControlName="message" id="message" rows="3" required></textarea>
                                        </div>
                                        <!-- <div class="form-group">
                                            <label for="name">Name <span class="text-danger">*</span></label>
                                            <input type="text" formControlName="name" class="form-control" required>
                                        </div> -->
                                        <div class="form-group">
                                            <label for="email">Your email <span class="text-danger">*</span></label>
                                            <input id="email" type="text" formControlName="email" class="form-control" required>
                                        </div>
                                        <div class="form-group">
                                            <label for="phone">Your phone <small>(Optional)</small></label>
                                            <input id="phone" type="text" formControlName="phone" maxlength="14" class="form-control">
                                        </div>
                                        <re-captcha (resolved)="handleCaptchaSuccess($event)"
                                                    [theme]="captchaSettingTheme"
                                                    [type]="captchaSettingType"
                                                    formControlName="reCaptcha"
                                                    [siteKey]="captchaSiteKey"></re-captcha>
                                        <div class="form-group mt-3">
                                            <button type="submit" [disabled]='!sendMessageForm.valid' class="btn default-btn">Send</button>
                                            <button type="button" (click)="messageForm = false;" class="btn ml-2">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </li>
                            <li class="icon time" *ngIf="accountantDetails.yearsInBusiness">
                                <fa-icon [icon]="faBusinessTime" title="Year(s) in Business" size="1x" class="header-icons"></fa-icon>
                                &nbsp;{{ accountantDetails.yearsInBusiness }} Year(s) In Business
                            </li>
                            <li class="icon time" *ngIf="accountantDetails.website">
                                <fa-icon [icon]="faGlobe" title="Website" size="1x" class="header-icons"></fa-icon>
                                &nbsp;<a href="{{ accountantDetails.website }}" target="_blank"> {{ accountantDetails.website }}</a>
                            </li>
                            <li class="icon clock" *ngIf="accountantDetails.joinedDate">
                                <fa-icon [icon]="faClock" title="Business Time" size="1x" class="header-icons"></fa-icon>
                                &nbsp;Joined {{ companyName }}
                                <ul class="multi">
                                    <li class="fill column"><span>{{ accountantDetails.joinedDate | date : "mediumDate" }}</span></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <app-fan-club-member-widget></app-fan-club-member-widget>
                </div>
            </div>
        </div>
    </div>
</div>
