<div class="container home-body">
    <div class="row justify-content-md-center">
      <div class="col col-lg-6 div-border">
        <div class='text-center success-box'>
            <img class="image-check" src='assets/images/white_check.png' alt="Image success">
            <div class='text-center heading-box'>{{ heading }}</div>
        </div>
        <div class="justify-content-center">
            <div class="input-group mb-3 mt-3" [innerHTML]="mainContent">
            </div>
            <div class="input-group mb-3" [innerHTML]="message"></div>
            <div class="input-group mb-3">
                If you would like further assistance, Please visit &nbsp;<a href='{{ supportPortalURL }}'>{{ companyName }} support</a>.
            </div>
            <div class="input-group mb-3">
                We value your business. Learn more about {{ companyName }} and how they help small business:&nbsp; <a href='{{ blogURL }}'>click here</a>
            </div>
            <div class="input-group mb-3 justify-content-md-center">
                <a type="button" name="button" class="btn close-btn" onclick='window.close();'>Close Tab</a>
            </div>
        </div>
      </div>
    </div>
</div>
