import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit, OnDestroy {

  heading: string;
  message: string;
  mainContent: string;
  title: string;
  companyName: string = environment.companyName;
  supportPortalURL: string = environment.supportPortalURL;
  blogURL: string = environment.blogURL;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.activatedRoute.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.heading = data.heading;
        this.message = data.message;
        this.mainContent = data.mainContent;
        this.title = `${this.heading} |  ${this.companyName}`;
        this.titleService.setTitle(this.title);
    });
    this.ngxService.stop();
    this.ngxService.stopBackground();
    this.toastr.success('Your password has been reset.', this.heading);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }
}
