import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EMPTY, Observable } from 'rxjs';
import { AccountantService } from './accountant.service';
import { NGXLogger } from 'ngx-logger';
import { TokenResolverService } from './token-resolver.service';
@Injectable({
  providedIn: 'root'
})
export class ProfileResolverService {
  accountantId: string;
  constructor(
    private router: Router,
    private accountantService: AccountantService,
    private ngxService: NgxUiLoaderService,
    private logger: NGXLogger,
    private tokenResolverService: TokenResolverService
  ) { }

  async resolve(route: ActivatedRouteSnapshot): Promise<object | Observable<never>> {
    this.ngxService.start();
    this.accountantId = route.paramMap.get('id');
    this.logger.info('INFO: -> ProfileResolverService -> accountantId', this.accountantId);
    console.log('INFO: ProfileResolverService: accountantId:', this.accountantId);
    await this.tokenResolverService.resolve();
    return this.accountantService.getAccountantDetails(this.accountantId).then(response => {
      this.logger.info('INFO: -> ProfileResolverService -> response', response);
      console.log('INFO: ProfileResolverService: response:', response);
      this.ngxService.stop();
      this.ngxService.stopBackground();
      return response;
    }).catch(error => {
      this.logger.error('ERROR: -> ProfileResolverService -> error', error);
      console.log('ERROR: -> ProfileResolverService -> error', error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
      this.router.navigate(['not-found']);
      return EMPTY;
    });
  }
}
