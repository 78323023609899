import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit, OnDestroy {

  heading: string;
  message: string;
  mainContent: string;
  title: string;
  error: string;
  errorDescription: string;
  companyName: string = environment.companyName;
  supportPortalURL: string = environment.supportPortalURL;
  blogURL: string = environment.blogURL;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.activatedRoute.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.heading = data.heading;
        this.message = data.message;
        this.mainContent = data.mainContent;
        this.title = `${this.heading} |  ${this.companyName}`;
        this.titleService.setTitle(this.title);
    });
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        if (params && params.error) {
          this.error = params.error;
        }
        if (params && params.error_description) {
          this.errorDescription = params.error_description;
        }
        this.ngxService.stop();
        this.ngxService.stopBackground();
        const cyrillicPattern = /\p{Script=Cyrl}/u;
        if ( cyrillicPattern.test(this.mainContent)) {
          this.toastr.error('The application has encountered an unknown error. ' +
            'Our technical staff have been  notified and will be looking into this asap.', this.heading);
        } else {
          this.toastr.error(this.mainContent, this.heading);
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }
}
