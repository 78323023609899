import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '@app/components/home/home.component';
import { NotFoundComponent } from '@app/components/not-found/not-found.component';
import { ErrorComponent } from '@app/components/error/error.component';
import { SuccessComponent } from '@app/components/success/success.component';
import { WriteAReviewComponent } from '@app/components/write-a-review/write-a-review.component';
import { ProfileComponent } from '@app/components/profile/profile.component';
import { WriteAReviewResolverService } from '@app/services/write-a-review-resolver.service';
import { ProfileResolverService } from './services/profile-resolver.service';
import { TokenResolverService } from './services/token-resolver.service';

const routes: Routes = [{
  path: '',
  component: HomeComponent,
  pathMatch: 'full',
  resolve: {
    token: TokenResolverService
  }
}, {
  path: 'profile/:id',
  component: ProfileComponent,
  pathMatch: 'full',
  resolve: {
    accountantDetails: ProfileResolverService
  }
}, {
  path: 'write-a-review',
  component: WriteAReviewComponent,
  pathMatch: 'prefix',
  resolve: {
    tokenDetails: WriteAReviewResolverService
  }
}, {
  path: 'invalid-link',
  component: ErrorComponent,
  pathMatch: 'prefix',
  data: {
    heading: 'Invalid Link',
    message: '',
    mainContent: 'This email link has expired or you have already submitted your review.'
  }
}, {
  path: 'thank-you',
  component: SuccessComponent,
  pathMatch: 'prefix',
  data: {
    heading: 'Review Submitted',
    message: 'this will help our other clients to find an accountant.',
    mainContent: 'Thanks you for your submission.'
  }
}, {
  path: 'error',
  component: ErrorComponent,
  pathMatch: 'prefix',
  data: {
    heading: 'ERROR',
    message: '',
    mainContent: 'A technical error has occurred.'
  }
}, {
  path: 'not-found',
  component: NotFoundComponent
}, {
  path: '**',
  component: NotFoundComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
