<div class="container home-body">
  <h3>Rate and Write a Review for <span class="font-italic">{{ accountantName }}</span></h3>
  <div class="content-box">
    <form method="post" name="review" class="local-form" (ngSubmit)="saveReview()" #review="ngForm">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            Select Your Rating
            <bar-rating [(rate)]="rating" [max]="5"></bar-rating>
            <span class="star-label"> {{ rating }} Stars</span>  
          </div>
        </div>
        <div class="col-md-6">
            <div class="form-group"> 
                <input type="email" class="form-control" name="email"
              placeholder="Email Address" required="required" [(ngModel)]="reviewerEmail" #validReviewerEmail="ngModel"/> 
            </div>
            <div [hidden]="validReviewerEmail.valid || validReviewerEmail.pristine"
                class="alert alert-danger">
              Email is required.
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group"> 
                <input type="text" class="form-control" name="name"
              placeholder="Your Name" required="required" [(ngModel)]="reviewerName" #validReviewerName="ngModel"/> 
            </div>
            <div [hidden]="validReviewerName.valid || validReviewerName.pristine"
                class="alert alert-danger">
              Name is required.
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group"> 
                <textarea name="message" class="form-control" rows="10"
              required="required" [(ngModel)]="message" #validMessage="ngModel">Help other to choose perfect accountant</textarea> 
            </div>
            <div [hidden]="validMessage.valid || validMessage.pristine"
                class="alert alert-danger">
              Message is required.
            </div>
        </div>
      </div>
      <div> 
          <button type="submit" class="btn submit-btn" [disabled]="!review.form.valid">Submit Review</button>
      </div>
    </form>
  </div>
</div>

