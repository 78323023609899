import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Accountant } from '@app/interfaces/accountant';

@Injectable({
  providedIn: 'root'
})
export class AccountantService {
  api: string = environment.ROCKSTAR_URL;
  constructor(
    private httpClient: HttpClient
  ) { }

  getAccountantProfilePic(accountantId: string): Promise<any> {
    return this.httpClient.post(this.api + '/get_accountant_image', { id: accountantId }).toPromise();
  }

  getAccountantCompanyPic(accountantId: string): Promise<any> {
    return this.httpClient.post(this.api + '/get_company_logo', { id: accountantId }).toPromise();
  }

  getAccountantsList(
    start: number,
    limit: number,
    filteredState: string,
    filteredServices: any[],
    filteredIndustries: any[],
    searchText: string
  ): Promise<any> {
    // tslint:disable-next-line: prefer-const
    const filter = [];
    if (filteredState) {
      filter.push({
        field: 'state',
        value: filteredState
      });
    }
    if (filteredServices && Array.isArray(filteredServices) && filteredServices.length > 0) {
      filter.push({
        field: 'service_provided',
        value: filteredServices
      });
    }
    if (filteredIndustries && Array.isArray(filteredIndustries) && filteredIndustries.length > 0) {
      filter.push({
        field: 'industry_served',
        value: filteredIndustries
      });
    }
    return this.httpClient.post(this.api + '/get_accountants_list', {
      start,
      limit,
      filter,
      searchText
    }).toPromise();
  }

  getAccountantDetails(accountantId: string): Promise<any> {
    return this.httpClient.post<Accountant>(this.api + '/get_accountant_details', { id: accountantId }).toPromise();
  }
}
