import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { FanClubMember } from '@app/interfaces/fan-club-member';

@Injectable({
  providedIn: 'root'
})
export class FanClubMemberService {
  api: string = environment.ROCKSTAR_URL;
  constructor(
    private httpClient: HttpClient
  ) { }

  getFanClubMembers(): Promise<any> {
    return this.httpClient.post<FanClubMember[]>(this.api + '/get_fan_club_members_list', {}).toPromise();
  }
}
