<footer class="footer">
    <div class="footer-content section">
        <div class="container">
            <div class="row footer-results">
                <div class="col-md-3 col-sm-6 f-box">
                    <div class="title">For Businesses</div>
                    <ul class="list-f">
                        <li><a href="{{ overviewLink }}">Overview</a></li>
                        <li><a href="{{ pricingLink }}">Pricing</a></li>
                        <li><a href="{{ accountingReportingLink }}">Accounting & Reporting</a></li>
                        <li><a href="{{ salesLink }}">Sales</a></li>
                        <li><a href="{{ purchasingLink }}">Purchasing</a></li>
                        <li><a href="{{ inventoryManagementLink }}">Inventory Management</a></li>
                        <li><a href="{{ projectAndTimeTrackingLink }}">Project and Time Tracking</a></li>
                        <li><a href="{{ whyCustomBooksLink }}">Why {{ companyName }}</a></li>
                    </ul>
                </div>
                <div class="col-md-3 col-sm-6 f-box">
                    <div class="title">For Partners</div>
                    <ul class="list-f">
                        <li><a href="{{ accountantAndBookkeeperLink }}">Accountants & Bookkeepers</a></li>
                        <li><a href="{{ integrationsLink }}">Integrations</a></li>
                        <li><a href="{{ developerLink }}">Developer</a></li>
                    </ul>
                    <div class="title"></div>
                </div>
                <div class="col-md-3 col-sm-6 f-box">
                    <div class="title">Company</div>
                    <ul class="list-f">
                        <li><a href="{{ aboutContactLink }}">About Us</a></li>
                        <li><a href="{{ careerLink }}">Careers</a></li>
                        <li><a href="{{ blogURL }}">Blogs</a></li>
                    </ul>
                </div>
                <div class="col-md-3 col-sm-6 f-box">
                    <div class="title">Support</div>
                    <ul class="list-f">
                        <li><a href="{{ getStartedLink }}">Get Started</a></li>
                        <li><a href="{{ productEnhancementsLink }}">Product Enhancements</a></li>
                        <li><a href="{{ userGuideLink }}">User Guide</a></li>
                        <li><a href="{{ accountingSuiteAcademyLink }}">{{ companyName }} Academy</a></li>
                        <li><a href="{{ supportPortalURL }}">Support Portal</a></li>
                        <li><a href="{{ privacyPolicyURL }}">Privacy Policy</a></li>
                        <li><a href="{{ termOfServiceURL }}">Terms of Use</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-md-8 footer-mini">
                    <div>
                        <ul class="socialIcons">
                            <li class="social">
                                <a href="{{ facebookURL }}/" target="_blank">
                                    <fa-icon [icon]="faFacebook"></fa-icon>
                                </a>
                            </li>
                            <li class="social">
                                <a href="{{ twitterURL }}" target="_blank">
                                    <fa-icon [icon]="faTwitter"></fa-icon>
                                </a>
                            </li>
                            <li class="social">
                                <a href="{{ youtubeURL }}" target="_blank">
                                    <fa-icon [icon]="faYoutube"></fa-icon>
                                </a>
                            </li>
                            <li class="social">
                                <a href="{{ linkedInURL }}" target="_blank">
                                    <fa-icon [icon]="faLinkedin"></fa-icon>
                                </a>
                            </li>
                            <li>Inquire: <a href="tel:{{ inquireNumber }}">{{ inquireNumber }}</a>,</li>
                            <li>Help: <a href="tel:{{ helpLineNumber }}">{{ helpLineNumber }}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4 footer-mini-right">
                    © {{currentDate | date: 'yyyy'}}, {{ companyName }}. All Rights Reserved.
                </div>
            </div>
        </div>
    </div>
</footer>