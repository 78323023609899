import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export interface Industry {
  id: string;
  name: string;
  disabled?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class IndustriesService {

  constructor() { }

  getIndustries(term: string = null): Observable<Industry[]> {
    let industries = getIndustries();
    if (term) {
      industries = industries.filter(x => x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
    }
    return of(industries).pipe();
  }
}

function getIndustries() {
  return [{
    id: 'agriculture_farming',
    name: 'Agriculture/Farming',
    disabled: false
  }, {
    id: 'automotivesales_repair',
    name: 'Automotive Sales/Repair',
    disabled: false
  }, {
    id: 'cannabis',
    name: 'Cannabis',
    disabled: false
  }, {
    id: 'computer_software',
    name: 'Computer/Software',
    disabled: false
  }, {
    id: 'construction_contractors',
    name: 'Construction/Contractors',
    disabled: false
  }, {
    id: 'consulting',
    name: 'Consulting',
    disabled: false
  }, {
    id: 'design_architecture',
    name: 'Design/Architecture',
    disabled: false
  }, {
    id: 'financial_services',
    name: 'Financial Services',
    disabled: false
  }, {
    id: 'government_agency',
    name: 'Government Agency',
    disabled: false
  }, {
    id: 'hospitality',
    name: 'Hospitality',
    disabled: false
  }, {
    id: 'insurance_brokrage',
    name: 'Insurance/Brokerage',
    disabled: false
  }, {
    id: 'lawncare',
    name: 'Lawn Care/Landscaping',
    disabled: false
  }, {
    id: 'legal',
    name: 'Legal',
    disabled: false
  }, {
    id: 'medical_dental',
    name: 'Medical/Dental/Health Services',
    disabled: false
  }, {
    id: 'manufacturing',
    name: 'Manufacturing',
    disabled: false
  }, {
    id: 'non_profit',
    name: 'Non Profit',
    disabled: false
  }, {
    id: 'property_management',
    name: 'Property Management',
    disabled: false
  }, {
    id: 'real_estate',
    name: 'Real Estate',
    disabled: false
  }, {
    id: 'restaurant',
    name: 'Restaurant',
    disabled: false
  }, {
    id: 'retail',
    name: 'Retail',
    disabled: false
  }, {
    id: 'telecommunication',
    name: 'Telecommunication',
    disabled: false
  }, {
    id: 'transportation',
    name: 'Transportation',
    disabled: false
  }, {
    id: 'wholesale',
    name: 'Wholesale',
    disabled: false
  }];
}
