<div class="map" *ngIf="isGoogleApiReady$ | async">
  <google-map [options]="googleMapOptions">
    <map-marker
      #mapMarker="mapMarker"
      [position]="marker.position"
      [label]="marker.label"
      [title]="marker.title"
      [draggable]="marker.draggable"
      (mapClick)="openInfoWindow(mapMarker)"
      [icon]="{ url: 'assets/images/map_marker_accountant.png' }"
      [options]="marker.options">
    </map-marker>
    <map-info-window>
      <div class="infobox-big">
        <div *ngIf="marker.address">
          <p>{{ marker.address }}</p>
        </div>
      </div>
    </map-info-window>
  </google-map>
</div>
